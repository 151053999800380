import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAwwRBKmQ0XWzwFze6-Pix5DSfwskT7iZg",
  authDomain: "operations-construbank-89b2d.firebaseapp.com",
  projectId: "operations-construbank-89b2d",
  storageBucket: "operations-construbank-89b2d.appspot.com",
  messagingSenderId: "737408932151",
  appId: "1:737408932151:web:85e6ba32a4ff04ee08a2b2"
};

firebase.initializeApp(firebaseConfig);
export default firebase;


