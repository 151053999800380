import React from "react";
import firebase from "../firebase";
import Logo from '../assets/logo.png'

const Login = () => {

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .catch((error) => {
          alert(error);
        });
      console.log('Logado!')
      console.log(`${firebase.auth().currentUser.email}`)
      window.location.href = "/";

    } catch (error) {
      alert(error);
    }
  };
  return (
    <div className="container mt-5 w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="mx-auto w-50 p-4 shadow border bg-white">
        <h1 className="text-center"><img className="w-50" src={Logo} alt="logo" /></h1>
        <form onSubmit={HandleSubmit} className="m-5">
          <div className="form-group">
            <label>Email</label>
            <input type="text" name="email" className="form-control" />
          </div>
          <div className="form-group">
            <label>Senha</label>
            <input type="password" name="password" className="form-control" />
          </div>
          <button className="btn btn-primary btn-block">Entrar</button>

        </form>
      </div>
    </div>
  );
};

export default Login;
