import React from "react";
import firebase from "../firebase";
import { Row, Col } from 'react-bootstrap';
import Operacoes from "./Operacoes";
import Logo from '../assets/logo.png'

const Home = () => {

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Row style={{ height: "20%" }}>
        <Col style={{ padding: "50px", display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
          <div>
            <img src={Logo} style={{ width: 250 }} alt="logo" />
          </div>

          <div>
            <button
              className="btn btn-danger"
              onClick={() => firebase.auth().signOut()}
            >
              Sair da conta
            </button>
          </div>
        </Col>
      </Row>
      <Row style={{ height: "80%", marginTop: 100 }}>
        <Col className="d-flex direction-column align-items-center justify-content-center">
          <Operacoes />

        </Col>
      </Row>
    </div>
  );
};

export default Home;
