import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { ListGroup, Modal, Button, Form, Spinner } from 'react-bootstrap';

import "firebase/database";

function Operacoes() {
    const [operacoes, setOperacoes] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [canhoto, setCanhoto] = useState(null);
    const [informe, setInforme] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pendenciaSelecionada, setPendenciaSelecionada] = useState(null);
    const [operacaoSelecionada, setOperacaoSelecionada] = useState(null)


    useEffect(() => {
        const operacoesRef = firebase.database().ref("operacoes/");
        operacoesRef.on("value", (snapshot) => {
            const operacoesData = snapshot.val();
            const operacoesPendentes = Object.entries(operacoesData)
                .filter(([id, data]) => data.cedente === firebase.auth().currentUser.displayName && data.status === "Pendente")
                .map(([id, data]) => {
                    const partes = data["data-da-operacao"].split("/");
                    const dia = partes[0].padStart(2, "0");
                    const mes = partes[1].padStart(2, "0");
                    const ano = partes[2];
                    const dataFormatada = `${dia}-${mes}-${ano}`;

                    return {
                        id,
                        numero: data["numero-da-operacao"],
                        status: data.status,
                        dataop: dataFormatada,
                        pendencias: data.pendencias,
                    };
                });
            setOperacoes(operacoesPendentes);
        });

        return () => operacoesRef.off();
    }, []);

    const handleInforme = (event) => {
        setInforme(event.target.files);
    };

    const handleCanhoto = (event) => {
        setCanhoto(event.target.files);
    };

    const uploadCanhoto = (operacao, canhoto) => {
        const storageRef = firebase.storage().ref();
        const file = canhoto[0];
        const metadata = { contentType: 'application/pdf' };
        const data = operacao.dataop.replace("/", "-");

        return storageRef
            .child(`operacoes/${data}/${operacao.numero}/canhotos/${file.name}`)
            .put(file, metadata);
    };

    const uploadInforme = (operacao, informe) => {
        const storageRef = firebase.storage().ref();
        const file = informe[0];
        const metadata = { contentType: 'application/pdf' };
        const data = operacao.dataop.replace("/", "-");

        return storageRef
            .child(`operacoes/${data}/${operacao.numero}/informes/${file.name}`)
            .put(file, metadata);
    };

    const changeStatus = async (operacao) => {

        const operacaoRef = firebase.database().ref(`operacoes/${operacao.id}`);

        operacaoRef.update({ status: 'Nenhuma Pendência' })
            .then(() => {
                console.log('Status atualizado com sucesso.');
            })
            .catch((error) => {
                console.error('Erro ao atualizar status: ', error);
            });
    }

    const handleSendData = async (operacao, canhoto, informe, pendencia) => {
        setIsLoading(true);
        console.log(`Sending data: ${pendencia}`)
        try {
            console.log(`Operação: ${operacao.numero}`)
            await uploadCanhoto(operacao, canhoto);
            await uploadInforme(operacao, informe);
            handleClose()

            if (canhoto && canhoto.length > 0 && informe && informe.length > 0) {
                const operacaoRef = firebase.database().ref(`operacoes/${operacao.id}`);
                if (operacao.pendencias.includes(pendencia)) {
                    const novasPendencias = operacao.pendencias.filter((item) => item !== pendencia);
                    await operacaoRef.update({ pendencias: novasPendencias })
                        .then(() => {
                            console.log('Pendência removida com sucesso.');
                            if (novasPendencias.length === 0) {
                                operacaoRef.update({ status: 'Nenhuma Pendência' })
                                    .then(() => {
                                        console.log('Status atualizado com sucesso.');
                                    })
                                    .catch((error) => {
                                        console.error('Erro ao atualizar status: ', error);
                                    });
                            }
                        })
                        .catch((error) => {
                            console.error('Erro ao remover pendência: ', error);
                        });
                }
            }

        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>

            <ListGroup>
                {operacoes && operacoes.length > 0 ? (
                    operacoes.map((operacao) => (
                        // código do ListGroup.Item
                        <ListGroup.Item key={operacao.id} className='d-flex flex-column justify-content-center align-items-center p-3'>
                            <div>
                                <b className="ml-5 mr-5">Data da Operação: <span className="text-primary"> {operacao.dataop}</span></b>

                                <b className="ml-5 mr-5">Número: <span className="text-primary"> {operacao.numero}</span></b>


                                <b className="ml-5 mr-5">Status: <span className="text-primary"> {operacao.status}</span></b>
                            </div>
                            <div className="w-100 mt-3 pl-5">
                                <p><b>Notas Pendentes</b></p>


                                <ListGroup>
                                    {operacao.pendencias
                                        ? operacao.pendencias.map((pendencia, index) => (
                                            <ListGroup.Item key={index}>{pendencia}
                                                <Button
                                                    className="mt-3 w-25 ml-5"
                                                    variant="primary"
                                                    onClick={() => {
                                                        setPendenciaSelecionada(pendencia);
                                                        setOperacaoSelecionada(operacao)
                                                        handleShow();
                                                    }}
                                                >
                                                    Enviar arquivos
                                                </Button></ListGroup.Item>

                                        ))

                                        : <p>Sem pendências por aqui</p>
                                    }

                                </ListGroup>

                            </div>


                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Enviar Documento</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {isLoading ? (
                                        <div className="d-flex justify-content-center">
                                            <Spinner animation="border" />
                                        </div>
                                    ) : (
                                        <div>
                                            {pendenciaSelecionada && (
                                                <Form className="mt-3">
                                                    <Form.Label>
                                                        <b>Pendencia: {pendenciaSelecionada}</b>
                                                    </Form.Label>
                                                    <Form.Group className="mt-2">
                                                        <Form.Label>
                                                            <b>Enviar Canhoto:</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            className="p-1"
                                                            onChange={handleCanhoto}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mt-2">
                                                        <Form.Label>
                                                            <b>Enviar Informe:</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            className="p-1"
                                                            onChange={handleInforme}
                                                        />
                                                    </Form.Group>
                                                    <div className="w-100 d-flex justify-content-center mt-3">
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                handleSendData(
                                                                    operacaoSelecionada,
                                                                    canhoto,
                                                                    informe,
                                                                    pendenciaSelecionada
                                                                )
                                                            }
                                                        >
                                                            Enviar arquivos
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}

                                        </div>)}
                                </Modal.Body>


                            </Modal>
                            <Button variant="success" onClick={changeStatus} className="mt-3 pl-4 pr-4">Finalizar Pendência</Button>
                        </ListGroup.Item>
                    ))
                ) : (<h1>Não há pendências!</h1>)}
            </ListGroup>



        </div >
    );
}

export default Operacoes;
