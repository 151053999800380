import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import firebase from "./firebase";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState(null);
  const [authCompleted, setAuthCompleted] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setAuthCompleted(true);
      console.log(user)
    });

    return unsubscribe;
  }, []);

  if (!authCompleted) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};


export default PrivateRoute;
